.settings-menu {
	margin-bottom: 30px;
	ul {
		display: block;
		padding: 0;
		li {
			& + li {
				margin-top: 15px;
			}
			a {
				color: #1B2559;
				padding: 0;
				border: 0 !important;
				display: flex;
				&.active {
					color: #7638ff !important;
					border: 0;
				}
			}
			i {
				margin-right: 10px;
				font-size: 20px;
				min-width: 25px;
			}
		}
	}
}
.settings-menu{
    ul{
        li{
            a{
                &:hover{
                    background-color: transparent !important;
                    border: 0 !important;
                }
                &:focus{
                    background-color: transparent !important;
                    border: 0 !important;
                }
            }
        }
    }
} 
.invoices-settings-card {
	border-radius: 5px;
	.card-title {
		color: #1B2559;
	}
}
.invoices-settings-form {
	label {
		color: #1B2559;
	}
	.form-control {
		height: 50px;
		border-radius: 6px;
	}
}
.invoices-upload-btn {
	border: 1px solid #dee2e6;
	border-radius: 6px;
	color: #000000;
	cursor: pointer;
	font-weight: 400;
	padding: 15px 15px;
	position: relative;
	width: 100%;
	height: 50px;
	.hide-input {
		position: relative;
		z-index: 1;
		cursor: pointer;
		min-height: auto;
		padding-left: 4px;
		padding-top: 0;
		line-height: 10px;
		width: 100%;
		opacity: 0;
	}
	.upload {
		position: absolute;
		background: #7638FF;
		border-radius: 4px;
		right: 6px;
		top: 8px;
		width: 136px;
		height: 32px;
		font-size: 14px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
}
.invoice-setting-btn {
	.btn {
		min-width: 160px;
		padding: 10px 0;
		color: #fff;
		border-radius: 8px;
	}
	.cancel-btn {
		background: #1B2559;
		&:hover {
			background: #7638FF;
		}
	}
	.btn-primary {
		&:hover {
			background: #1B2559;
			border: 1px solid #1B2559;
		}
	}
}
.invoices-items-main-tabs {
	border-bottom: 3px solid #F3F3F3;
	padding-bottom: 10px;
}
.invoices-items-tabs {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			display: inline-block;
			padding-left: 36px;
			padding-right: 36px;
			position: relative;
			&:last-child {
				padding-right: 0;
			}
			a {
				font-weight: 600;
				font-size: 16px;
				color: #1B2559;
				padding-bottom: 24px;
				position: relative;
				&.active {
					color: #7638FF;
					&:after {
						content: "";
						position: absolute;
						left: 50%;
						bottom: 4px;
						width: 130px;
						height: 6px;
						background-color: #7638FF;
						transform: translateX(-50%);
						-webkit-transform: translateX(-50%);
					}
				}
				&:hover {
					color: #7638FF;
				}
			}
		}
	}
}
.circle-btn {
	width: 33px;
	height: 33px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
