body{
	font-family: $__body__font__family;
	font-size:.9375rem;
	color:#455560;
	line-height: 1.5;
	background-color: #f7f8f9;
}
.container{
	width:$__full__width;
	max-width:$__container__width;
	padding-left:$__grid__padding__left;
	padding-right:$__grid__padding__right;
	margin:$__margin__auto;
  margin-top: 55px;
}
img {
	max-width:$__full__width;
	height: $__auto;
}
*{
	outline: $__none;

}
button{
	&:focus{
		box-shadow: none !important;
	}
}
a{
	color:$__primarycolor;
	cursor: pointer;
	text-decoration: $__none;
	@include transition(all 0.2s ease);
	&:hover{
		color: $__primarycolorhover;
		@include transition(all 0.2s ease);
	}
	&:focus {
		outline: 0;
	}
}
p{
	margin-bottom:20px;
	&:last-child{
		margin-bottom:0;
	}
}
strong{
	font-weight: $__bold;
}
