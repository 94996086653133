// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$template-primary: mat.define-palette(mat.$indigo-palette);
$template-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$template-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$template-theme: mat.define-light-theme((color: (primary: $template-primary,
        accent: $template-accent,
        warn: $template-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($template-theme);


// regular style toast
@import "node_modules/ngx-toastr/toastr";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "node_modules/bootstrap/scss/bootstrap.scss";

@import "./assets/scss/main.scss";

.siderbar-view.show-sidebar {
  right: 600px;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  z-index: 99999;
}

.icons-items {
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.icons-list {
  max-height: calc(100vh - 328px);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  overflow-y: scroll;
  background: #ffff;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.tag_input {
  mat-form-field {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;

    .mat-form-field-flex {
      padding: 0.4em 0.75em 0.4em 0.75em;
    }

    mat-chip-list {
      mat-chip {
        background-color: #7638ff !important;
        color: #fff !important;
        border-radius: 5px !important;

        mat-icon {
          color: white;
        }
      }
    }
  }
}

.show-sub-menu {
  display: block !important;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
}

.hide-sub-menu {
  display: none !important;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
}

.switch {
  .active {
    +.slider {
      background-color: $__primarycolor;
      border: 2px solid $__primarycolor;

      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(16px);
        background: $__white;
      }
    }
  }
}

.feather {
  font-size: 18px;
}

.mini-sidebar .menu-title {
  display: none !important;
}

.table_header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  .dataTables_length {
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    select {
      width: auto;
      display: inline-block;
      margin: 0px 3px;
    }
  }

  .dataTables_filter {
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    input {
      width: auto;
      display: inline-block;
      margin: 0px 3px;
      border: 1px solid #e5e5e5;
      color: #1b2559;
      height: 40px;
      border-radius: 8px;
      padding-left: 30px;
    }
  }
}

.table_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1.5rem 1.5rem;

  .pagination_section {
    display: flex;
    justify-content: flex-end;
  }
}

.edit-options .feather {
  font-size: 14px;
}

.list-inline-item span {
  margin: 0 5px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #eee #eee #fff;
}

.mat-button-base {
  background: $__primarycolor;
  color: #fff;
}

.sidebar-three {
  .sidebar-menu-three {
    .tab-content-three {
      a {
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

.tab {
  .tablinks {
    display: block;
    background-color: inherit;
    color: #6e82a5;
    padding: 22px 24px !important;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 0px 0px;
    font-size: 17px;
    border: 0 !important;
    transition: all 0.2s ease;
  }

}

.submenu-five {
  .dropdown-toggle::after {
    border-top: 0;
  }
}

.mini-sidebar {
  .header {
    .header-left.header-left-two {
      .logo.logo-small {
        padding: 10px 0 0;
        margin: 0;
      }
    }
  }
}

.apexcharts-legend {
  opacity: 0 !important;
}
